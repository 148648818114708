import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import tw from "tailwind.macro";
import ChevronDownIcon from "components/icons/ChevronDown";
import { useSitecoreContext } from "stores/sitecoreContext";
import tailwindConfig from "tailwind.config.js";
import cx from "classnames";
import resolveConfig from "tailwindcss/resolveConfig";
import { FieldTypes } from "@sitecore-jss/sitecore-jss-react-forms";
import useBreakpoint from "utils/hooks/useBreakpoint";
import breakpoints from "utils/breakpoints";
import {
  validateConditions,
  reviewActions,
} from "utils/sitecoreConditionFunctions";
import { scrollToSection } from "utils/scrollTo";
import { useCountryValue } from "stores/country";
import { validateEmail } from "utils/validateEmail";
import { validateZipCode } from "utils/validateZipCode";
import { validateTel } from "utils/validateTel";
import useMarketCommunitiesForm from "utils/hooks/useMarketCommunitites";
import useDynamicsForm from "utils/hooks/useDynamicsForm";

const fullConfig = resolveConfig(tailwindConfig);

const ValidationBubble = styled.div`
  background: #fff;
  margin: 0 auto;
  padding: 6px;
  text-align: center;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
  border-radius: 5px;
  border: 1px solid #9b9b9b;
  margin-left: 8rem;
  margin-top: 5.75rem;
  z-index: 25;
  color: #0b2c71;
  font-size: 1rem;

  &:after {
    content: "";
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
    -moz-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    top: -6px;
    left: 20px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent #fff #fff transparent;
  }
`;

const InputContainer = styled.div.attrs(({ className }) => ({
  className: `w-full flex justify-center mb-2 relative ${className || ""}`,
}))`
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  input[type="date"]::-webkit-datetime-edit {
    text-transform: uppercase;
  }

  input[type="email"] {
    text-transform: lowercase;
  }

  @media ${breakpoints.md} {
    ${({ inline, width }) =>
      inline
        ? `
          display: inline-block;
          width: ${width};
        `
        : `
          padding: 0 0.175rem;
        `}
  }

  ${({ fieldTypeItemId }) =>
    fieldTypeItemId === FieldTypes.MultipleLineText && "margin-bottom: 1.5rem;"}

  & label {
    display: none;
  }
  & input,
  & textarea {
    outline: none;

    &:focus {
      border: 2px solid ${fullConfig.theme.colors["mattamy-blue"]} !important;

      ${({ isFieldRequired, empty }) =>
        isFieldRequired &&
        empty &&
        `color: ${fullConfig.theme.colors["field-error"]};`};
    }

    &:focus::-webkit-input-placeholder {
      ${({ isFieldRequired, error }) =>
        (isFieldRequired || error) &&
        `color: ${fullConfig.theme.colors["field-error"]};`};
    }
  }

  & *:not(button):not(svg):not(div):not(option) {
    ${tw`border font-graphie rounded-none font-semibold px-4 py-2 w-full rounded bg-white text-mattamy-blue bg-white font-normal`}
    border-color: rgba(179, 195, 205, 0.9);
    line-height: 1.75rem;
    height: 3.125rem;

    ${({ error }) =>
      error
        ? tw`border-field-error text-field-error bg-white`
        : tw`border-white`}
  }

  & select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    outline: none;

    & > option {
      color: ${fullConfig.theme.colors["mattamy-blue"]};
    }

    ${({ error }) => {
      if (error) return tw`text-field-error`;
      return tw`text-mattamy-blue`; // Default to text-mattamy-blue if no error
    }}

    &:focus {
      border: 2px solid ${fullConfig.theme.colors["mattamy-blue"]} !important;

      ${({ isFieldRequired, empty }) =>
        isFieldRequired &&
        empty &&
        `color: ${fullConfig.theme.colors["field-error"]};`};
    }
  }

  & textarea {
    height: 8.125rem !important;
  }
`;

const CheckboxContainer = styled.div`
  & label {
    ${tw`flex items-center`}
    ${({ error }) => {
      if (error) return tw`text-field-error border-field-error`;
    }}
  }
  & input {
    appearance: none;
    width: ${fullConfig.theme.width["4"]};
    min-width: ${fullConfig.theme.width["4"]};
    height: ${fullConfig.theme.height["4"]};
    min-height: ${fullConfig.theme.height["4"]};
    ${({ error }) =>
      error
        ? `border: 2px solid ${fullConfig.theme.colors["field-error"]};`
        : `border: 2px solid ${fullConfig.theme.colors["mattamy-blue"]};`}
    border-radius: 100%;
    margin-right: ${fullConfig.theme.margin["3"]};
    align-self: flex-start;
    margin-top: 0.25rem;

    &:checked {
      background: ${fullConfig.theme.colors["mattamy-blue"]};
    }

    &:focus {
      ${({ isFieldRequired }) =>
        isFieldRequired &&
        `border: 2px solid ${fullConfig.theme.colors["field-error"]};
        color: ${fullConfig.theme.colors["field-error"]};
        `};
    }
  }
`;

const ButtonContainer = styled.div`
  button {
    color: ${fullConfig.theme.colors["white"]};
    font-family: ${fullConfig.theme.fontFamily["trade-gothic-20"][0]};
    letter-spacing: 0.6px;
    border-radius: 10px;
    text-align: center;
    ${({ isMobile }) => (isMobile ? `width: 100%;` : `width: 15.5rem;`)}
    height: 3.75rem;
    background-color: ${fullConfig.theme.colors["mattamy-blue"]};
    padding-top: 2px;
  }
`;

const IconContainer = styled.div.attrs(({ className }) => ({
  className: `absolute w-6 top-0 bottom-0 m-auto flex items-center z-10 ${className}`,
}))`
  pointer-events: none;
  right: 1rem;
`;

const isFieldByName = ({ field }, name) => {
  return field.model && field.model.name === name;
};

const isEmpty = (value) => {
  if (!value) {
    return true;
  }
  if (Array.isArray(value) && (!value.length || !value[0])) {
    return true;
  }
  return false;
};

const getnextInvalidElement = (country) => {
  const requiredInputs = document.querySelectorAll(
    "input[type=text]:required,input[type=email]:required,input[type=tel]:required,textarea:required,select:required"
  );
  let focusIndex = -1;

  for (let i = 0; i < requiredInputs.length; i++) {
    if (
      requiredInputs[i].value === "" ||
      (requiredInputs[i].type === "email" &&
        !validateEmail(requiredInputs[i].value)) ||
      (requiredInputs[i].type === "tel" &&
        !validateTel(requiredInputs[i].value)) ||
      (requiredInputs[i].type === "text" &&
        requiredInputs[i].ariaLabel &&
        (requiredInputs[i].ariaLabel.includes("Zip") ||
          requiredInputs[i].ariaLabel.includes("zip")) &&
        !validateZipCode(requiredInputs[i].value, country))
    ) {
      focusIndex = focusIndex === -1 ? i : focusIndex;
      requiredInputs[
        i
      ].style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
      if (requiredInputs[i].tagName === "SELECT") {
        requiredInputs[
          i
        ].style.color = `${fullConfig.theme.colors["field-error"]}`;
        requiredInputs[
          i
        ].parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["field-error"]}`;
      }
      requiredInputs[i].classList.remove("placeholder_mattamy_blue");
      requiredInputs[i].classList.add("placeholder_error");
    }
  }
  if (requiredInputs.length > 0 && focusIndex > -1) {
    return requiredInputs[focusIndex];
  }
  const validateInputs = document.querySelectorAll(
    "input[type=email], input[type=tel]"
  );
  focusIndex = -1;
  for (let i = 0; i < validateInputs.length; i++) {
    if (
      (validateInputs[i].value.length > 0 &&
        validateInputs[i].type === "email" &&
        !validateEmail(validateInputs[i].value)) ||
      (validateInputs[i].value.length > 0 &&
        validateInputs[i].type === "tel" &&
        !validateTel(validateInputs[i].value))
    ) {
      focusIndex = focusIndex === -1 ? i : focusIndex;
      validateInputs[
        i
      ].style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
      if (validateInputs[i].tagName === "SELECT") {
        validateInputs[
          i
        ].style.color = `${fullConfig.theme.colors["field-error"]}`;
        validateInputs[
          i
        ].parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["field-error"]}`;
      }
      validateInputs[i].classList.remove("placeholder_mattamy_blue");
      validateInputs[i].classList.add("placeholder_error");
    }
  }
  if (validateInputs.length > 0 && focusIndex > -1) {
    return validateInputs[focusIndex];
  }

  return null;
};

const ScheduleAVisitField = (props = {}) => {
  const breakpoint = useBreakpoint(
    typeof window !== "undefined" && window.innerWidth
  );
  const isMobile = ["sm", "xs"].includes(breakpoint);

  const { ContextID = {} } = useSitecoreContext() || {};
  const elementRef = useRef(null);
  const [{ country }] = useCountryValue();
  const {
    marketCommunitiesForm,
    showMarketCommunitiesForm,
  } = useMarketCommunitiesForm();
  const {
    updateDynaMXFormID,
    updateDynaMXFormCountryInputID,
    updateDynaMXFormDropdown1ID,
    updateDynaMXFormDropdown2ID,
    updateDynaMXFormDropdown3ID,
    updateDynaMXHomeTypesDDId,
    updateDynaMXPriceRangesDDId,
    updateDynaMXIsAgentCBId,
    updateDynaMXIsTextMessageCBId,
    updateDynaMXIsConsentCBId,
  } = useDynamicsForm();

  const title = props.field && props.field.model && props.field.model.title;
  const name = props.field && props.field.model && props.field.model.name;
  const fieldTypeItemId = props.field.model.fieldTypeItemId;
  const isFieldRequired = props.field.model.required;
  const fieldConditionKey = props.field.model.conditionSettings.fieldKey;
  const hasConditions =
    props.field.model.conditionSettings &&
    props.field.model.conditionSettings.fieldConditions.length > 0;

  const dynaMXFormID =
    name === "dynaMXFormID"
      ? props.field && props.field.model && props.field.model.value
      : null;
  const dynaMXCountryInputID =
    name === "country"
      ? props.field && props.field.model && props.field.model.itemId
      : null;
  const dynaMXDropdown1ID =
    name === "dd1"
      ? props.field && props.field.model && props.field.model.itemId
      : null;
  const dynaMXDropdown2ID =
    name === "dd2"
      ? props.field && props.field.model && props.field.model.itemId
      : null;
  const dynaMXDropdown3ID =
    name === "dd3"
      ? props.field && props.field.model && props.field.model.itemId
      : null;
  const dynaMXHomeTypesDDId =
    name === "home-types"
      ? props.field && props.field.model && props.field.model.itemId
      : null;
  const dynaMXPriceRangesDDId =
    name === "price-ranges"
      ? props.field && props.field.model && props.field.model.itemId
      : null;
  const dynaMXIsAgentCheckBoxId =
    name === "realestate"
      ? props.field && props.field.model && props.field.model.itemId
      : null;
  const dynaMXIsTextMessageCheckBoxId =
    name === "sms-notifications"
      ? props.field && props.field.model && props.field.model.itemId
      : null;
  const dynaMXIsConsentCheckBoxId =
    name === "terms"
      ? props.field && props.field.model && props.field.model.itemId
      : null;

  useEffect(() => {
    if (dynaMXFormID) {
      updateDynaMXFormID(dynaMXFormID);
    }
  }, [dynaMXFormID]);
  useEffect(() => {
    if (dynaMXCountryInputID) {
      updateDynaMXFormCountryInputID(dynaMXCountryInputID);
    }
  }, [dynaMXCountryInputID]);
  useEffect(() => {
    if (dynaMXDropdown1ID) {
      updateDynaMXFormDropdown1ID(dynaMXDropdown1ID);
    }
  }, [dynaMXDropdown1ID]);
  useEffect(() => {
    if (dynaMXDropdown2ID) {
      updateDynaMXFormDropdown2ID(dynaMXDropdown2ID);
    }
  }, [dynaMXDropdown2ID]);
  useEffect(() => {
    if (dynaMXDropdown3ID) {
      updateDynaMXFormDropdown3ID(dynaMXDropdown3ID);
    }
  }, [dynaMXDropdown3ID]);
  useEffect(() => {
    if (dynaMXHomeTypesDDId) {
      updateDynaMXHomeTypesDDId(dynaMXHomeTypesDDId);
    }
  }, [dynaMXHomeTypesDDId]);
  useEffect(() => {
    if (dynaMXPriceRangesDDId) {
      updateDynaMXPriceRangesDDId(dynaMXPriceRangesDDId);
    }
  }, [dynaMXPriceRangesDDId]);
  useEffect(() => {
    if (dynaMXIsAgentCheckBoxId) {
      updateDynaMXIsAgentCBId(dynaMXIsAgentCheckBoxId);
    }
  }, [dynaMXIsAgentCheckBoxId]);
  useEffect(() => {
    if (dynaMXIsTextMessageCheckBoxId) {
      updateDynaMXIsTextMessageCBId(dynaMXIsTextMessageCheckBoxId);
    }
  }, [dynaMXIsTextMessageCheckBoxId]);
  useEffect(() => {
    if (dynaMXIsConsentCheckBoxId) {
      updateDynaMXIsConsentCBId(dynaMXIsConsentCheckBoxId);
    }
  }, [dynaMXIsConsentCheckBoxId]);

  useEffect(() => {
    if (elementRef) {
      const element = elementRef.current;
      if (element && fieldTypeItemId === FieldTypes.DropdownList) {
        //console.log(title)
        const select = element.querySelector("select");
        select.setAttribute("title", title);
        for (let i = 0; i < select.options.length; i++) {
          let option = select.options[i];
          if (option.value !== "") {
            option.text = option.text.replace("&#x27;", "'");
          }
        }
        if (title.includes("Country")) {
          let option;
          for (let i = 0; i < select.options.length; i++) {
            option = select.options[i];
            if (option.value !== "") option.setAttribute("lang", "en");
          }
          const emptyOption =
            select && select.options ? select.options[0] : null;
          if (emptyOption && !emptyOption.value) {
            emptyOption.label = "";
            emptyOption.text = "";
            emptyOption.disabled = true;
            emptyOption.setAttribute("value", "");
          }
        }
        if (title.includes("Community of")) {
          select.setAttribute("required", true);
          const emptyOption =
            select && select.options ? select.options[0] : null;
          if (emptyOption && !emptyOption.value) {
            emptyOption.label = "";
            emptyOption.text = "";
            emptyOption.disabled = true;
            emptyOption.setAttribute("value", "");
          }
          for (let i = 0; i < marketCommunitiesForm.length; i++) {
            const newOption = document.createElement("option");
            newOption.value = marketCommunitiesForm[i].communityID;
            newOption.textContent = marketCommunitiesForm[i].displayName;
            select.appendChild(newOption);
          }
        }
        if (name.includes("dd") && isFieldRequired) {
          select.setAttribute("required", true);
          const emptyOption =
            select && select.options ? select.options[0] : null;
          if (emptyOption && !emptyOption.value) {
            emptyOption.label = "";
            emptyOption.text = "";
            emptyOption.disabled = true;
            emptyOption.setAttribute("value", "");
          }
        }
      }

      if (fieldTypeItemId === FieldTypes.Button) {
        const submitButton = element.querySelector("button");
        if (submitButton) {
          submitButton.addEventListener("click", function (event) {
            setTimeout(() => {
              const nextFocusInput = getnextInvalidElement(country);
              if (nextFocusInput) {
                scrollToSection(
                  !isMobile ? "sales-center-contact" : "ScheduleAVisit"
                );
                nextFocusInput.focus({ preventScroll: false });
              }
            }, 100);
          });
        }
      }

      const input =
        element &&
        (element.querySelector("input") ||
          element.querySelector("select") ||
          element.querySelector("textarea"));
      if (input) {
        if (name === "ContextID") {
          let labelInput =
            input.parentElement && input.parentElement.firstElementChild;
          if (labelInput) labelInput.innerHTML = name;
        }

        input.setAttribute("placeholder", "");
        input.classList.add("placeholder_mattamy_blue");
        if (isFieldByName(props, "ContextID")) {
          input.setAttribute("value", ContextID.value);
        }
        if (isFieldByName(props, "first-name")) {
          const re = `[a-zA-Z ]+`;
          input.setAttribute("pattern", re);
        }
        if (isFieldByName(props, "last-name")) {
          const re = `[a-zA-Z ]+`;
          input.setAttribute("pattern", re);
        }
        if (input.type === "email") {
          //const re = `[a-z0-9._+-]+[a-z0-9]@[a-z0-9.-]+\\.[a-z]{2,4}$`;
          //const re = `^[a-z0-9._-+]+[a-z0-9]@[a-z0-9.-]+\.[a-z]{2,4}$`;
          //input.setAttribute("pattern", re);

          input.addEventListener(
            "change",
            function (event) {
              event.preventDefault();

              let validationBubble = this.parentElement.lastElementChild;

              if (this.value === "") {
                validationBubble.classList.remove("hidden");
              } else {
                if (this.type === "email" && !validateEmail(this.value)) {
                  validationBubble.classList.remove("hidden");
                  validationBubble.textContent =
                    "Error, Email addresses must contain a username, ‘@’, and ‘.com’";
                  event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                  event.target.classList.add("placeholder_error");
                  event.target.classList.remove("placeholder_mattamy_blue");
                  input.setAttribute(
                    "aria-label",
                    "Error, Email addresses must contain a username, ‘@’, and ‘.com’"
                  );
                  input.setAttribute("aria-live", "polite");
                } else {
                  validationBubble.classList.add("hidden");
                  event.target.style.border = `1px solid ${fullConfig.theme.colors["white"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                  event.target.classList.remove("placeholder_error");
                  event.target.classList.add("placeholder_mattamy_blue");
                  input.setAttribute("aria-label", "Email");

                  return false;
                }
              }
            },
            false
          );
        }
        if (input.type === "text") {
          if (title.includes("Zip") || title.includes("zip")) {
            input.addEventListener("change", (event) => {
              let validationBubble =
                event.target.parentElement.lastElementChild;

              if (event.target.value !== "") {
                if (validateZipCode(event.target.value, country)) {
                  event.target.style.border = `1px solid ${fullConfig.theme.colors["white"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                  event.target.classList.remove("placeholder_error");
                  event.target.classList.add("placeholder_mattamy_blue");
                  validationBubble.classList.add("hidden");
                  input.setAttribute("aria-label", title);
                } else {
                  event.target.setAttribute("aria-live", "polite");
                  input.setAttribute(
                    "aria-label",

                    "Invalid " +
                      // (country === "USA" ? "US" : "CAN") +
                      " postal Code"
                  );
                  validationBubble.textContent =
                    "Invalid " +
                    //(country === "USA" ? "US" : "CAN") +
                    " postal Code";
                  event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                  validationBubble.classList.remove("hidden");
                  event.target.classList.remove("placeholder_mattamy_blue");
                  event.target.classList.add("placeholder_error");
                }
              }
            });
          }
          if (title.includes("name") || title.includes("Name")) {
            input.addEventListener("change", (event) => {
              let validationBubble =
                event.target.parentElement.lastElementChild;
              if (event.target.value !== "") {
                let reg = /^[A-Za-z ]+$/;
                if (reg.test(event.target.value)) {
                  validationBubble.classList.add("hidden");
                  event.target.style.border = `1px solid ${fullConfig.theme.colors["white"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                  event.target.classList.remove("placeholder_error");
                  event.target.classList.add("placeholder_mattamy_blue");
                  input.setAttribute("aria-label", title);
                } else {
                  validationBubble.textContent =
                    "Invalid " + title.toLowerCase();
                  event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                  validationBubble.classList.remove("hidden");
                  event.target.classList.remove("placeholder_mattamy_blue");
                  event.target.classList.add("placeholder_error");
                  event.target.setAttribute("aria-live", "polite");
                  //code handlebubble

                  input.setAttribute(
                    "aria-label",
                    "Invalid " + title.toLowerCase()
                  );
                }
              } else {
                validationBubble.classList.add("hidden");
                event.target.style.border = `1px solid ${fullConfig.theme.colors["white"]}`;
                event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                event.target.classList.remove("placeholder_error");
                event.target.classList.add("placeholder_mattamy_blue");
                input.setAttribute("aria-label", title);
              }
            });
          }
        }
        if (input.type === "checkbox" && name === "sms-notifications") {
          input.onclick = (e) => {
            let target = e.target;
            const form = target.form;
            let telephoneField = form.querySelectorAll("input[type=tel]")[0];
            if (telephoneField) {
              if (target.checked) {
                telephoneField.setAttribute("required", true);
              } else {
                telephoneField.removeAttribute("required");
                let validationBubble =
                  telephoneField.parentElement.lastElementChild;
                validationBubble.classList.add("hidden");

                telephoneField.style.border = `1px solid ${fullConfig.theme.colors["white"]}`;
                telephoneField.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                telephoneField.classList.remove("placeholder_error");
                telephoneField.classList.add("placeholder_mattamy_blue");
              }
            }
          };
        }
        if (isFieldRequired) {
          const emailAutoComplete =
            "Email, Email addresses must contain a username, ‘@’, and ‘.com’";
          input.setAttribute(
            "aria-label",
            title === "Email"
              ? emailAutoComplete
              : title.replace("*", "") + " Field is Required"
          );

          input.setAttribute("required", true);
          input.addEventListener(isFieldRequired ? "invalid" : "", function (
            event
          ) {
            const emailAutoComplete =
              "Error, Email addresses must contain a username, ‘@’, and ‘.com’";
            input.setAttribute(
              "aria-label",
              title === "Email"
                ? emailAutoComplete
                : "Error, " + title.replace("*", "") + " Field is Required"
            );

            event.preventDefault();
            let validationBubble;
            if (this.type === "checkbox") {
              validationBubble = this.parentElement.parentElement
                .lastElementChild;
              if (!this.checked) {
                validationBubble.classList.remove("hidden");
              } else validationBubble.classList.add("hidden");
            } else {
              validationBubble = this.parentElement.lastElementChild;
              if (this.value === "") {
                validationBubble.textContent =
                  "Error, " + title.replace("*", "") + " Field is Required";
                validationBubble.classList.remove("hidden");
              } else {
                if (this.type === "email" && !validateEmail(this.value)) {
                  validationBubble.classList.remove("hidden");
                  validationBubble.textContent =
                    "Error, Email addresses must contain a username, ‘@’, and ‘.com’";
                  event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                  event.target.classList.add("placeholder_error");
                  event.target.classList.remove("placeholder_mattamy_blue");
                } else {
                  if (this.type === "tel" && !validateTel(this.value)) {
                    validationBubble.classList.remove("hidden");
                    validationBubble.textContent =
                      "Error, Phone number must be 10 digits.";
                    event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                    event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                    event.target.classList.add("placeholder_error");
                    event.target.classList.remove("placeholder_mattamy_blue");
                  } else {
                    if (
                      (this.ariaLabel.includes("Zip") ||
                        this.ariaLabel.includes("zip")) &&
                      !validateZipCode(this.value, country)
                    ) {
                      validationBubble.classList.remove("hidden");
                      validationBubble.textContent =
                        "Invalid " +
                        //(country === "USA" ? "US" : "CAN") +
                        " postal Code";
                      event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                      event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                      event.target.classList.add("placeholder_error");
                      event.target.classList.remove("placeholder_mattamy_blue");
                    } else {
                      if (
                        this.ariaLabel.includes("name") &&
                        this.value !== ""
                      ) {
                        validationBubble.classList.remove("hidden");
                        validationBubble.textContent = `Invalid ${title}`;
                        event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                        event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                        event.target.classList.add("placeholder_error");
                        event.target.classList.remove(
                          "placeholder_mattamy_blue"
                        );
                      } else {
                        if (
                          this.ariaLabel.includes("name") &&
                          this.value === ""
                        ) {
                          const fieldName = this.ariaLabel.split(".")[0];
                          validationBubble.classList.remove("hidden");
                          validationBubble.textContent =
                            "Error, " +
                            title.replace("*", "") +
                            " Field is Required";
                          event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                          event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                          event.target.classList.add("placeholder_error");
                          event.target.classList.remove(
                            "placeholder_mattamy_blue"
                          );
                        } else {
                          validationBubble.classList.add("hidden");
                          event.target.style.border = `1px solid ${fullConfig.theme.colors["white"]}`;
                          event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                          event.target.classList.remove("placeholder_error");
                          event.target.classList.add(
                            "placeholder_mattamy_blue"
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          });
        }
        if (isFieldRequired || title.includes("Community of")) {
          if (
            !(
              input.type === "text" &&
              (title.includes("Zip") ||
                title.includes("zip") ||
                title.includes("name") ||
                title.includes("Name"))
            )
          ) {
            input.addEventListener("change", (event) => {
              let validationBubble =
                event.target.parentElement.lastElementChild;
              if (event.target.value !== "") {
                event.target.style.border = `1px solid ${fullConfig.theme.colors["white"]}`;
                event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                event.target.classList.remove("placeholder_error");
                event.target.classList.add("placeholder_mattamy_blue");
                validationBubble.classList.add("hidden");
                if (event.target.tagName === "SELECT") {
                  event.target.parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                }
                if (
                  event.target.type === "email" &&
                  !validateEmail(event.target.value)
                ) {
                  validationBubble.textContent =
                    "Error, Email addresses must contain a username, ‘@’, and ‘.com’";
                  event.target.dispatchEvent(new Event("invalid"));
                  validationBubble.classList.remove("hidden");
                  event.target.classList.add("placeholder_error");
                  event.target.classList.remove("placeholder_mattamy_blue");
                }
              } else {
                validationBubble.classList.remove("hidden");
                event.target.classList.remove("placeholder_error");
                event.target.classList.add("placeholder_mattamy_blue");
              }
            });
          }
        }

        if (input.type === "tel") {
          input.setAttribute("aria-live", "polite");
          input.setAttribute("pattern", `[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{10}`);
          input.onkeypress = (e) => {
            let charCode = e && e.which ? e.which : e.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
            const telNumber = e.target.value.replace(/\D/g, "");
            if (telNumber.length > 9) return false;
            return true;
          };
          input.onkeyup = (e) => {
            let target = e.target;
            if (target) {
              let targetValue = e.target.value;
              const keyCode = e.keyCode;
              targetValue = targetValue.replace(/\D/g, "");
              let actualCursorPosition = target.selectionStart;
              if (targetValue.length === 3 && keyCode !== 8) {
                actualCursorPosition += 1;
              }
              if (targetValue.length === 6 && keyCode !== 8) {
                actualCursorPosition += 1;
              }
              const telCode = targetValue.substring(0, 3);
              const telPart1 = targetValue.substring(3, 6);
              const telPart2 = targetValue.substring(6, 10);
              if (
                !e.target.value ||
                e.target.value == "-" ||
                e.target.value == "--"
              ) {
                e.target.value = "";
              } else {
                e.target.value = telCode + "-" + telPart1 + "-" + telPart2;
              }
              //target.dispatchEvent(new Event("change"));
            }
          };
          input.onchange = (e) => {
            let target = e.target;
            target.focus();
            if (target) {
              let targetValue = e.target.value;
              const keyCode = e.keyCode;
              targetValue = targetValue.replace(/\D/g, "");
              let actualCursorPosition = target.selectionStart;
              if (targetValue.length === 3 && keyCode !== 8) {
                actualCursorPosition += 1;
              }
              if (targetValue.length === 6 && keyCode !== 8) {
                actualCursorPosition += 1;
              }

              let validationBubble = target.parentElement.lastElementChild;
              if (targetValue.length > 0 && targetValue.length < 10) {
                e.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                e.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                validationBubble.textContent =
                  "Error, Phone number must be 10 digits.";
                validationBubble.classList.remove("hidden");
                input.setAttribute(
                  "aria-label",
                  "Error, Phone number must be 10 digits."
                );
              } else {
                e.target.style.border =
                  targetValue.length === 0
                    ? `0px solid ${fullConfig.theme.colors["white"]}`
                    : `1px solid ${fullConfig.theme.colors["white"]}`;
                e.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                validationBubble.classList.add("hidden");
                input.setAttribute(
                  "aria-label",
                  "Phone, number must be 10 digits."
                );
              }
              const telCode = targetValue.substring(0, 3);
              const telPart1 = targetValue.substring(3, 6);
              const telPart2 = targetValue.substring(6, 10);
              if (
                !e.target.value ||
                e.target.value == "-" ||
                e.target.value == "--"
              ) {
                e.target.value = "";
              } else {
                e.target.value = telCode + "-" + telPart1 + "-" + telPart2;
              }
              target.setSelectionRange(
                actualCursorPosition,
                actualCursorPosition
              );
            }
          };

          input.addEventListener("invalid", function (event) {
            //console.log("here!!!", this.required);
            event.preventDefault();
            let validationBubble = this.parentElement.lastElementChild;
            if (this.value === "" && this.required) {
              input.setAttribute("aria-label", "Phone. Field is Required");
              validationBubble.textContent =
                "Error, " + title.replace("*", "") + " Field is Required";
              validationBubble.classList.remove("hidden");
              event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
              event.target.setAttribute("aria-live", "polite");
            } else {
              if (this.value === "" && !this.required) {
                validationBubble.classList.add("hidden");
                input.setAttribute("aria-label", "Phone . Must be 10 digits");
              } else {
                if (!validateTel(this.value)) {
                  validationBubble.classList.remove("hidden");
                  validationBubble.textContent =
                    "Error, Phone number must be 10 digits.";

                  event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                  event.target.setAttribute("aria-live", "polite");
                } else {
                  input.setAttribute("aria-label", "Phone. Must be 10 digits");
                  validationBubble.classList.add("hidden");
                  event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                }
              }
            }
          });
        }
      }
    }

    if (isFieldByName(props, "ContextID")) {
      props.onChange(props.field.valueField.name, ContextID.value, true, null);
    }
  }, [elementRef, country, showMarketCommunitiesForm]);

  useEffect(() => {
    if (hasConditions) {
      const conditions =
        props.field.model.conditionSettings.fieldConditions[0].conditions;
      const actions =
        props.field.model.conditionSettings.fieldConditions[0].actions;
      const matchType =
        props.field.model.conditionSettings.fieldConditions[0].matchTypeId;

      let flag = validateConditions(conditions, matchType);
      reviewActions(actions, flag);
    }
  });

  if (isFieldByName(props, "disclaimer")) {
    return <p className="disclaimer">{props.field.model.text}</p>;
  }

  if (isFieldByName(props, "title1") || isFieldByName(props, "title2")) {
    return (
      <h3 className="font-trade-gothic-20 text-4xl text-mattamy-blue mt-4">
        {props.field.model.text}
      </h3>
    );
  }

  if (
    fieldTypeItemId === "{B67C70C1-6776-4BCB-8529-AA28E02F6412}" ||
    isFieldByName(props, "elqFormName") ||
    isFieldByName(props, "elqPromoCode") ||
    isFieldByName(props, "title") ||
    isFieldByName(props, "subtitle") ||
    isFieldByName(props, "ReCaptcha")
  ) {
    //console.log(fieldTypeItemId);
    return null;
  }

  if (fieldTypeItemId === FieldTypes.Checkbox) {
    return (
      <CheckboxContainer
        error={props.errors && props.errors[0]}
        id={fieldConditionKey}
        ref={elementRef}
        className="w-full text-mattamy-blue my-2 flex content-start"
      >
        {props.children}
      </CheckboxContainer>
    );
  }

  if (fieldTypeItemId === FieldTypes.Button) {
    return (
      <ButtonContainer
        isMobile={isMobile}
        className="flex-auto text-right mb-2 mt-10 lg:mt-4"
        ref={elementRef}
      >
        {props.children}
      </ButtonContainer>
    );
  }

  const isAmountField = isFieldByName(props, "amount");
  const isCurrenyField = isFieldByName(props, "currency");

  let width = "100%";
  if (isAmountField) {
    width = "70%";
  } else if (isCurrenyField) {
    width = "30%";
  }
  if (title.includes("Community of") && showMarketCommunitiesForm === false)
    return null;
  if (
    (isFieldByName(props, "dd1") ||
      isFieldByName(props, "dd2") ||
      isFieldByName(props, "dd3") ||
      isFieldByName(props, "home-types") ||
      isFieldByName(props, "price-ranges")) &&
    props.field.model.items &&
    props.field.model.items.length === 0
  )
    return null;
  return (
    <InputContainer
      onFocus={(e) => e.target.removeAttribute("aria-live")}
      className={cx(props.field.model.cssClass, {
        "md:pr-10": isAmountField,
      })}
      empty={isEmpty(props.value)}
      title={title}
      error={props.errors && props.errors[0]}
      isFieldRequired={isFieldRequired}
      ref={elementRef}
      fieldTypeItemId={fieldTypeItemId}
      inline={isAmountField || isCurrenyField}
      width={width}
      id={fieldConditionKey}
      aria-autocomplete="list"
      autocomplete="on"
    >
      <div className="flex flex-col w-full my-2">
        <div className="text-mattamy-blue font-bold my-2">
          {title +
            ((isFieldRequired || title.includes("Community of")) &&
            !title.includes("*")
              ? " *"
              : "")}
        </div>
        {props.children}
        <ValidationBubble className="hidden">
          {`Error, ${title.replace("*", "")} field is required`}
        </ValidationBubble>
      </div>
      {props.field.model.items && (
        <IconContainer className="-mr-2 h-full mt-5">
          <ChevronDownIcon className={"w-4"} />
        </IconContainer>
      )}
    </InputContainer>
  );
};

export default ScheduleAVisitField;
