import React, { useRef } from "react";
import styled from "styled-components";
import tw from "tailwind.macro";
import breakpoints from "utils/breakpoints";
import useOnClickOutside from "utils/hooks/useOnClickOutside";
import ChevronDown from "components/icons/ChevronDown";
import { Transition } from "react-spring/renderprops";
import useBreakpoint from "utils/hooks/useBreakpoint";
import cx from "classnames";

const DropDown = styled.div`
  ${({ small }) => (small ? `height: 2.5rem;` : `height: 3.125rem;`)}
`;

const DropDownButton = styled.div`
  ${({ outlined, isMobile }) =>
    (outlined || isMobile) && tw`border border-action-blue`}
  ${({ active, activeFilter }) => activeFilter && tw`text-white bg-action-blue`}
  ${({ showNewSchemaColor, newSchemaColorHexaCode }) =>
    showNewSchemaColor ? `background-color: ${newSchemaColorHexaCode};` : ""}
  ${({ showNewSchemaColor, newSchemaTextColorHexaCode }) =>
    showNewSchemaColor ? `color: ${newSchemaTextColorHexaCode};` : ""}
`;

const DropDownContent = styled.div`
  top: 100%;
  ${({ showNewSchemaColor, newSchemaColorHexaCode }) =>
    showNewSchemaColor ? `background-color: ${newSchemaColorHexaCode};` : ""}
  ${({ showNewSchemaColor, newSchemaTextColorHexaCode }) =>
    showNewSchemaColor ? `color: ${newSchemaTextColorHexaCode};` : ""}

  ${({ fullWidthMobile }) =>
    fullWidthMobile &&
    `
    width: calc(100vw - 2.5rem);
    right: 0;
  `}

  @media ${breakpoints.sm} {
    right: unset;
    width: auto;
  }

  @media ${breakpoints.md} {
    left: 0;
  }
`;

const LabelContainer = styled.div`
  width: 87%;
  line-height: 1.125rem;
`;

const Dropdown = (props) => {
  const {
    label,
    children,
    activeFilter,
    active,
    setActive = () => {},
    outlined = false,
    small = false,
    lightBlue,
    darkBlue,
    showNewSchemaColor,
    newSchemaColorHexaCode,
    newSchemaTextColorHexaCode,
    withSelectClick,
    textLightBlue,
    onSelectFilterClick = () => {},
    truncateLabel = true,
    fullWidthMobile,
    style,
    dropdownName = "dropdown",
    dropdownDescription = "",
    ariaPressed,
    isTypeDropdown = false,
    rotateChevronDown = false,
  } = props;

  const breakpoint = useBreakpoint(
    typeof window !== "undefined" && window.innerWidth
  );
  const isMobile = ["sm", "xs"].includes(breakpoint);

  // Click outside functionality
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setActive(false));
  const dropdownButtonRef = useRef();

  return (
    <DropDown
      className="relative h-full text-xs font-graphie w-auto select-none"
      ref={dropdownRef}
      small={small}
      style={style}
      tabIndex={withSelectClick ? 0 : "-1"}
    >
      <DropDownButton
        showNewSchemaColor={showNewSchemaColor}
        newSchemaColorHexaCode={newSchemaColorHexaCode}
        newSchemaTextColorHexaCode={newSchemaTextColorHexaCode}
        className={cx(
          "h-full w-full flex justify-between cursor-pointer text-left rounded-lg transition transition-all duration-300 ",

          {
            "hover:text-white hover:bg-action-blue bg-white md:text-action-blue ": !showNewSchemaColor,
            //text colors
            "sm:text-action-blue": lightBlue,
            "md:text-action-blue": lightBlue,
            "lg:text-action-blue": lightBlue,
            "xs:text-action-blue": lightBlue,
            "text-action-blue": lightBlue,
            "xs:text-white": darkBlue,
            "md:text-white": darkBlue,
            "lg:text-white": darkBlue,
            "sm:text-white": darkBlue,
            "text-white": darkBlue,
            "text-action-blue": textLightBlue,
            /*"xs:text-white": showNewSchemaColor,
            "md:text-white": showNewSchemaColor,
            "lg:text-white": showNewSchemaColor,
            "sm:text-white": showNewSchemaColor,
            "text-white": showNewSchemaColor,*/

            //bg colors
            "md:bg-light-blue": lightBlue,
            "bg-mattamy-blue": darkBlue,
            "hover:bg-mattamy-blue": darkBlue,
            "lg:hover:bg-mattamy-blue": darkBlue,
            "md:hover:bg-mattamy-blue": darkBlue,
            "sm:hover:bg-mattamy-blue": darkBlue,
            "xs:hover:bg-mattamy-blue": darkBlue,
            "lg:hover:bg-action-blue": lightBlue,
            "md:hover:bg-action-blue": lightBlue,
            "sm:hover:bg-action-blue": lightBlue,
            "xs:hover:bg-action-blue": lightBlue,
            "sm:bg-mattamy-blue": darkBlue,
            /*"bg-black hover:bg-black": showNewSchemaColor,
            "lg:bg-black lg:hover:bg-black": showNewSchemaColor,
            "md:bg-black md:hover:bg-black": showNewSchemaColor,
            "sm:bg-black sm:hover:bg-black": showNewSchemaColor,
            "xs:bg-black xs:hover:bg-black": showNewSchemaColor,*/

            //on click
            "px-4": !withSelectClick,
          }
        )}
        ref={dropdownButtonRef}
        active={active}
        activeFilter={activeFilter}
        isMobile={isMobile}
        onClick={(e) => {
          if (withSelectClick) {
            onSelectFilterClick();
            const focusElement = document.activeElement;
            if (focusElement) {
              const current = dropdownButtonRef.current;
              if (current && current.isEqualNode(focusElement)) {
              } else {
                e.preventDefault();
                focusElement.click();
              }
            }
          } else {
            setActive(!active);
          }
        }}
        outlined={outlined}
        aria-label={dropdownDescription || label + " selector"}
        aria-expanded={active}
        aria-pressed={ariaPressed}
      >
        <LabelContainer
          className={cx(
            "h-full flex flex-col justify-center text-sm overflow-visible",
            {
              "pl-4": withSelectClick,
              "focus:outline-none": !withSelectClick,
            }
          )}
          tabIndex={"-1"}
        >
          <span
            className={cx("whitespace-pre", {
              truncate: truncateLabel,
              "break-normal": !truncateLabel,
            })}
          >
            {label}
          </span>
        </LabelContainer>
        <button
          aria-expanded={active}
          role="button"
          className={cx("flex items-center justify-center h-full", {
            "pr-4": withSelectClick,
            "focus:outline-none": !withSelectClick,
          })}
          onClick={(e) => {
            setActive(!active);
            e.stopPropagation();
          }}
          aria-label={dropdownDescription + " " + dropdownName + " list"}
        >
          <ChevronDown
            tabIndex={withSelectClick ? "0" : "-1"}
            className={cx("w-10-mattamy", {
              "transform rotate-180": active && rotateChevronDown,
            })}
          />
        </button>
      </DropDownButton>
      <Transition
        items={active}
        config={{ duration: 100 }}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {(active) =>
          active &&
          // eslint-disable-next-line react/display-name
          ((props) => (
            <div style={props}>
              <DropDownContent
                showNewSchemaColor={showNewSchemaColor}
                newSchemaColorHexaCode={newSchemaColorHexaCode}
                newSchemaTextColorHexaCode={newSchemaTextColorHexaCode}
                className={cx(
                  "absolute w-full md:w-auto bg-action-blue text-white text-xs",
                  "mt-2 z-10 rounded-lg font-graphie tracking-tighter",
                  {
                    "bg-mattamy-blue": darkBlue,
                    "lg:bg-mattamy-blue": darkBlue,
                    "md:bg-mattamy-blue": darkBlue,
                    "sm:bg-mattamy-blue": darkBlue,
                    "xs:bg-mattamy-blue": darkBlue,
                    "px-4 py-3": !isTypeDropdown,
                    "px-8 py-6": isTypeDropdown,
                    /*"bg-black": showNewSchemaColor,
                    "lg:bg-black": showNewSchemaColor,
                    "md:bg-black": showNewSchemaColor,
                    "sm:bg-black": showNewSchemaColor,
                    "xs:bg-black": showNewSchemaColor,*/
                  }
                )}
                fullWidthMobile={fullWidthMobile}
              >
                {children}
              </DropDownContent>
            </div>
          ))
        }
      </Transition>
    </DropDown>
  );
};

export default Dropdown;
